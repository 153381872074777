import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//import axios from "axios";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddElectionsForm from "./addElectionsForm";
import CuttingForm from "./cuttingForm";
import getElections from "../../Api/Elections/getElections";
import createElections from "../../Api/Elections/createElections";
import { useUserStore } from "../../stores/userStore";
import updateElections from "../../Api/Elections/updateElections";
import getInfoElections from "../../Api/Elections/getInfoElections";
import { dataGridStyles } from "../../styleGrid";
import { formatDate } from "../../data/Utils/timeZones";
import { typesEl } from "../../data/Utils/types";
import ArchiveCreate from "../../Api/ArchiveData/ArchiveCreate";
//import enumSub from "../../Api/Subdivisions/enumSub";

const addElections = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [elections, setElections] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const [selectedElection, setSelectedElection] = useState(null);
  //const [subDistricts, setSubDistricts] = useState(null);
  const [showCuttingForm, setShowCuttingForm] = useState(false);
  const token = useUserStore((state) => state.token);
  

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "election_name",
      headerName: "Название выборов",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "election_type",
      headerName: "Тип выборов",
      width: 150,
      cellClassName: "name-column--cell",
      renderCell:(params) => (
        params.row.election_type && params.row.election_type !== null 
            ? typesEl.find((type) => type.value === params.row.election_type).label
            : ""
      )
    },
    {
      field: "starts_at",
      headerName: "Дата начала проведения выборов",
      width: 150,
      renderCell: (params) => (
        formatDate(params.row.starts_at)
       ),
    },
    {
      field: "ends_at",
      headerName: "Дата окончания выборов",
      width: 150,
      renderCell: (params) => (
      formatDate(params.row.ends_at)
     ),
    },
    {
      field: "edit",
      headerName: "Управление",
      flexDirection: "column",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isUseSubDistricts = params.row.is_subdivided
        const lengthDist = params.row.subdivisions.length > 0;
        const buttonText = isUseSubDistricts && lengthDist
          ? "Редактировать нарезку"
          : "Сделать нарезку";

        return (
          <>
            <Button
          variant="outlined"
          style={{color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleEditElection(params.row.election_id)}
        >
          Редактировать
        </Button>
            {isUseSubDistricts && (
              <Button
                variant="outlined"
                onClick={() =>
                  handleFormSubs(params.row)
                }
                style={{ margin: "5px", color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
              >
                {buttonText}
              </Button>
            )}

          <Button
            variant="outlined"
            style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
            onClick={() => archiveElection(params.row.election_id)}
          >
            Архивировать
        </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getElections({}).then((response) => {
      if(response.success){
        setElections(response.data.elections);
        setForce(false);
      }else {
        setElections([]);
        alert(response.message)
        setForce(false);
      }
    });
  }, [force]);

  const addElection = async (election) => {
    await createElections({ 
      "user_initiator":{
        "usr_token": token
      },
     "election":election 
    }).then((response)=>{
      if(response.success){
        setForce(true);
        setOpen(false);
        alert("Выборы успешно добавлены!")
      } else {
        alert(response.message)
      }
     })
  };

  const updateElection = async (update) => {
      const dataToSend = {
        "user_initiator": {
          "usr_token": token
        },
        "election": update
      };
  
      const updateResponse = await updateElections(dataToSend);
  
      if (updateResponse.success) {
        setSelectedElection(null);
        setForce(true);
        setOpen(false);
        alert("Выборы успешно обновлены!")
      } else {
        alert(updateResponse.message)
      }
  };

  const archiveElection = async (id) => {
    // Показ предупреждающего окна с кнопками "Отмена" и "Хорошо"
    const userConfirmed = window.confirm("Вы уверены, что хотите начать процесс архивации? Это действие нельзя отменить.");
    
    if (!userConfirmed) {
        // Если пользователь нажал "Отмена", выходим из функции
        return;
    }

    // Данные для отправки на сервер
    const dataToSend = {
        "user_initiator": {
            "usr_token": token
        },
        "election": {
            "election_id": id
        }
    };

    // Выполняем запрос на архивацию
    const updateResponse = await ArchiveCreate(dataToSend);

    // Показ сообщения о результате
    if (updateResponse.success) {
        setForce(true);
        alert(updateResponse.data.sys_msg);
    } else {
        alert(updateResponse.message);
    }
};


  const handleEditElection = (electionID) => {
    let payload = {
      "election":{
        "election_id":electionID
      }
    }

    getInfoElections(payload).then((resp) => {
      if (resp.success){
        setSelectedElection(resp.data.election);
        handleClickOpen();
      } else {
        alert(resp.message)
      }
    })
  };

  const handleFormSubs = (election) => {
    setSelectedElection(election);
    setShowCuttingForm(true);
  };

  const handleFormSubsClose = () => {
    setShowCuttingForm(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedElection(null);
  };

  // useEffect(() => {
  //   if (!open) {
  //     setSelectedElection(null);
  //   }
  // }, [open]);

  const handleOpenAddDialog = () => {
    return (
      <div>
        <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
          Добавить выборы
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Укажите данные по проводимым выборам"}
          </DialogTitle>
          <DialogContent>
            <AddElectionsForm
              addElection={addElection}
              selectedElection={selectedElection}
              updateElection={updateElection}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const rows = elections.map(district => {
    return {
      id: district.election_id,
      ...district
    };
  });

  return (
    <>
      {showCuttingForm ? (
        <CuttingForm
          selectedElection={selectedElection}
          onClose={handleFormSubsClose}
        />
      ) : (
        <Box m="20px">
          <Header title="Перечень выборов" />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={dataGridStyles}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ Toolbar: handleOpenAddDialog }}
              checkboxSelection
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default addElections;
