import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { 
  Box, 
  useTheme,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, } from '@mui/material';
import { tokens } from '../../../theme';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Header from "../../../components/Header";
import AddCountyModal from "../../../modals/countyModalCreate"
import { dataGridStyles } from "../../../styleGrid";
import { useUserStore } from "../../../stores/userStore";
import createSub from "../../../Api/Subdivisions/createSub";
import enumSub from "../../../Api/Subdivisions/enumSub";
import updateSub from "../../../Api/Subdivisions/updateSub";
import deleteSub from "../../../Api/Subdivisions/deleteSub";

const cuttingForm = ({ selectedElection, onClose }) => {
  cuttingForm.propTypes = {
        selectedElection: PropTypes.any,
        subDistricts: PropTypes.any,
      };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //const [regions, setRegions] = useState([]);
  const [open, setOpen] = useState(false);
  const token = useUserStore((state) => state.token);
  const [data, setData] = useState([]);
  const [force, setForce] = useState(true);
  const [selectedSub, setSelectedSub] = useState(null);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Название округа",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
        field: "edit",
        headerName: "Управление",
        flexDirection: 'column',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <>
               <Button
                  variant="outlined"
                  style={{color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
                  onClick={() => handleEditSub(params.row)}
                >
                Редактировать
              </Button>

              <Button
                variant="outlined"
                style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
                onClick={() => handleDeleteCounty(params.row.id)}
                >
                Удалить
              </Button>
            </>
          );
        },
      }
  ];

  const handleCreateCounty = async (data) => {

    let payload = {
      "user_initiator":{
          "usr_token": token
      },
      "el_subdivision": data
      }

    await createSub(payload).then((resp) => {
      if (resp.success){
        setForce(true);
        setOpen(false);
        alert(resp.data.sys_msg)
      } else {
        alert(resp.message)
      }
    })

    console.log("CREATE SUB TEST", data)
  };

  const handleUpdateCounty = async (data) => {

    let payload = {
      "user_initiator":{
          "usr_token": token
      },
      "el_subdivision": data
      }

    await updateSub(payload).then((resp) => {
      if (resp.success){
        setForce(true);
        setOpen(false);
        alert(resp.data.sys_msg)
      } else {
        alert(resp.message)
      }
    })

    console.log("UPDATED SUB TEST", data)
  };

  const handleDeleteCounty = async (id) => {

    let payload = {
      "user_initiator":{
          "usr_token": token
      },
      "el_subdivision": {
        "sel_id": id
       }
      }

    await deleteSub(payload).then((resp) => {
      if (resp.success){
        setForce(true);
        setOpen(false);
        alert(resp.data.sys_msg)
      } else {
        alert(resp.message)
      }
    })

    console.log("DELETED SUB TEST", data)
  };

  const getSubs = async (election) => {
    let payload = {
      "el_subdivision": {
       "election_id": election.election_id
       }
  }

  await enumSub(payload).then((resp) => {
    if (resp.success){
      setData(resp.data.el_subdivisions);
      setForce(false);
    } else {
      setData([]);
      alert(resp.message)
      setForce(false);
    }
  })
  }



  const rows = data.map(sub => {
    return {
      id: sub.sel_id,
      ...sub
    };
  });

  useEffect(() => {
    if(force) getSubs(selectedElection);
  }, [force]);

// useEffect(() => {
//       setRegions([{ id: subDistricts.id, name: subDistricts.name }]);
//   }, []);

//Окно добавления + стабильность страницы и другие методы (удаление, редактирование)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if(selectedSub){
      setSelectedSub(null);
    }
  };

  const handleEditSub = (row) => {
    setSelectedSub(row);
    setOpen(true);
  }

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить округ
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Настройте округ"}
        </DialogTitle>
        <DialogContent>
          <AddCountyModal
            onCreateCounty={handleCreateCounty}
            selectedElection={selectedElection}
            selectedSub={selectedSub}
            onEdit={handleUpdateCounty}
          />
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  return (
    <Box m="20px">
  <Header 
  title={`${selectedElection?.election_name}`} 
  backButton={
  <IconButton color="inherit" onClick={onClose} aria-label="close">
    <ArrowBackRoundedIcon />
  </IconButton>
  } 
  />
     <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
        
       {rows && 
       <DataGrid 
           rows={rows} 
           columns={columns} 
           components={{ Toolbar: handleOpenAddDialog  }} 
           localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />}
    </Box>
    </Box>
  );
};

export default cuttingForm;