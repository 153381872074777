import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
//import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SlideshowRoundedIcon from "@mui/icons-material/SlideshowRounded"
import DrawIcon from '@mui/icons-material/Draw';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HailIcon from '@mui/icons-material/Hail';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import PropTypes from "prop-types";
import { useUserDataStore } from "../../stores/userStore";
import { determineRoleCTYPE } from "../../data/Utils/accessControlConst";
import { SimCardAlert } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  Item.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    selected: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.primary[1000],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const userInfo = useUserDataStore((state) => state.user);

  const [open, setOpen] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [openMapViolations, setMapViolations] = useState(false);
  const [openRoadMap, setOpenRoadMap] = useState(false);
  const [openProtocols, setOpenProtocols] = useState(false);
  const [openNotifications, setNotifications] = useState(false);
  const [sends, setSends] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOpenEducation = () => {
    setOpenEducation(!openEducation);
  };

  const handleClickOpenMapViolations = () => {
    setMapViolations(!openMapViolations);
  };
  const handleClickOpenRoadMap = () => {
    setOpenRoadMap(!openRoadMap);
  };

  const handleClickOpenProtocols = () => {
    setOpenProtocols(!openProtocols);
  };

  const handleClickOpenNotifications = () => {
    setNotifications(!openNotifications);
  };

  const handleClickOpenSends = () => {
    setSends(!sends);
  };

  //console.log('TEST ROLE', determineRoleCTYPE(userInfo.access_data.access_id))

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[1200]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 18px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#970D0D !important",
        },
        "& .pro-menu-item.active": {
          color: "#970D0D !important",
        },
      }}
    >
      {userInfo && 
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon style={{color: colors.redAccent[1000]}} /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.primary[1000],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon style={{color: colors.redAccent[1000]}} />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            <Item
              title="Главная"
              to="/dashboard"
              icon={<HomeOutlinedIcon style={{color: colors.redAccent[1000]}}/>}
              selected={selected}
              setSelected={setSelected}
            />

          {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
           <>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon style={{color: colors.redAccent[1000] , marginLeft: 10}}/>
              </ListItemIcon>
               {!isCollapsed && <ListItemText primary="Настройка системы" style={{ color: colors.primary[1000] }} />}
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  {/* //TODO: СМЕНИ НАХУЙ НА 0 на 1 */}
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Админы"
                  to="/admins"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Пользователи"
                  to="/users"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Статусы системы"
                  to="/statuses"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }

              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Регионы системы"
                  to="/regions"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Партии системы"
                  to="/parties"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }

              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Кандидаты системы"
                  to="/candidates"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Районы системы"
                  to="/districts"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              
              {/* {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Списки УИКов"
                  to="/yiks"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              } */}
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Списки ИК"
                  to="/iks"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              } 
              {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
                <Item
                  title="Выборы системы"
                  to="/elections"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              </List>
            </Collapse>
            </>
            }

            <ListItemButton onClick={handleClickOpenEducation}>
              <ListItemIcon>
                <SchoolIcon style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Обучение" style={{ color: colors.primary[1000] }}/>}
              {openEducation ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openEducation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <Item
                  title="Справочник наблюдателя"
                  to="/observerHandBook"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                <Item
                  title="Памятки"
                  to="/memos"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Краткие памятки"
                  to="/briefMemos"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Законы и нормативные акты"
                  to="/lawsAndRegulations"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Образцы жалоб"
                  to="/sampleComplaints"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Видеолекции"
                  to="/videoLections"
                  icon={<SlideshowRoundedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </List>
            </Collapse>

          {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
          <>
            <ListItemButton onClick={handleClickOpenMapViolations}>
              <ListItemIcon>
                <SimCardAlert style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Карта нарушений" style={{ color: colors.primary[1000] }}/>}
              {openMapViolations ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMapViolations} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Item
                  title="Достоверные нарушения"
                  to="/violations"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Недостоверные нарушения"
                  to="/violationsInvalid"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Опубликованные нарушения"
                  to="/violationsPublish"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Архив нарушений"
                  to="/archiveIncendents"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </List>
            </Collapse>
            </>
           }
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Дорожные карты
            </Typography> */}

          {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
          <>
            <ListItemButton onClick={handleClickOpenRoadMap}>
              <ListItemIcon>
                <DrawIcon style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Дорожные карты" style={{ color: colors.primary[1000] }}/>}
              {openRoadMap ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openRoadMap} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Item
                  title="Дорожные карты системы"
                  to="/roadmaps"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Просмотр дорожных карт"
                  to="/roadmapList"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </List>
            </Collapse>
            </>
             }

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Протоколы
            </Typography> */}
             {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
             <>
            <ListItemButton onClick={handleClickOpenProtocols}>
              <ListItemIcon>
                <ReceiptLongIcon style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Протоколы" style={{ color: colors.primary[1000] }} />}
              {openProtocols ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openProtocols} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Item
                  title="Протоколы системы"
                  to="/protocols"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Поля протолоков"
                  to="/pfields"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Присланные протоколы"
                  to="/protocolsMap"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Недостоверные протоколы"
                  to="/invalidProtocols"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </List>
            </Collapse>
            </>
             }

          {[35].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
            <>
            <ListItemButton onClick={handleClickOpenSends}>
              <ListItemIcon> 
                <ForwardToInboxIcon style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Отправка" style={{ color: colors.primary[1000] }}/>}
              {sends ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sends} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Item
                  title="Отправить протокол"
                  to="/sendProtocol"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                {/* <Item
                  title="Присланные протоколы"
                  to="/protocolsMap"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
              </List>
            </Collapse>
            </>
            }

          {[35].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
            <Item
              title="Отслеживание работников"
              to="/usersWork"
              icon={<HailIcon style={{color: colors.redAccent[1000], marginLeft: 10}} />}
              selected={selected}
              setSelected={setSelected}
            />
          }

         {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
          <>
            <ListItemButton onClick={handleClickOpenNotifications}>
              <ListItemIcon>
                <SimCardAlert style={{color: colors.redAccent[1000], marginLeft: 10}}/>
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Уведомления" style={{ color: colors.primary[1000] }}/>}
              {openNotifications ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openNotifications} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Item
                  title="Рассылка уведомлений"
                  to="/sendNotifications"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

              </List>
            </Collapse>
            </>
           }

            <Item
              title="Явка"
              to="/presenceStat"
              icon={<DirectionsWalkIcon style={{color: colors.redAccent[1000], marginLeft: 10}} />}
              selected={selected}
              setSelected={setSelected}
            />

          {/* {[35, 36, 37, 39, 40, 42, 43].includes(determineRoleCTYPE(userInfo.access_data.access_id)?.[0]) &&
            <Typography
              variant="h6"
              color={colors.primary[1000]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Статистика
            </Typography> */}

          <Item
             title="Статистика"
             to="/"
             icon={<DirectionsWalkIcon style={{color: colors.redAccent[1000], marginLeft: 10}} />}
             selected={selected}
             setSelected={setSelected}
            />

            {/* <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
}
    </Box>
  );
};

export default Sidebar;
